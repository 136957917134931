export const state = () => ({
  // Déclare ici tes variables d'état (state)
  instances: [],
  instance: null,
  params: {},
  campusInfo: {},
  infoGenerales: {},
  detailInfo: {},
  diplomas: [],
  campusDiplomas: [],
})

export const mutations = {
  // Déclare ici tes mutations pour modifier le state
  setInstances(state, instances) {
    state.instances = instances
  },
  setInstance(state, instance) {
    state.instance = instance
  },
  setParams(state, params) {
    state.params = params
  },
  setCampusInfo(state, campusInfo) {
    state.campusInfo = campusInfo
  },
  setDetailInfo(state, detailInfo) {
    state.detailInfo = detailInfo
  },
  setDiplomas(state, diplomas) {
    state.diplomas = diplomas
  },
  setCampusDiplomas(state, campusDiplomas) {
    state.campusDiplomas = campusDiplomas
  },
  setInfoGenerales(state, infoGenerales) {
    state.infoGenerales = infoGenerales
  }
}

export const actions = {
  // Déclare ici tes actions pour effectuer des opérations asynchrones

  async nuxtServerInit({ commit}, { req }) {
    let instances = await this.$http.$get(`${process.env.strapiBaseUri}/api/instances?populate=homePageCardImage`);
    instances = instances.data;
    instances.sort((a,b) =>  a.attributes.ordreHomePage - b.attributes.ordreHomePage )

    commit('setInstances', instances);

    let infoGenerales = await this.$http.$get(`${process.env.strapiBaseUri}/api/info-generale`);
    infoGenerales = infoGenerales.data;

    commit('setInfoGenerales', infoGenerales);

  }

}

export const getters = {
  // Déclare ici tes getters pour récupérer les valeurs du state
}