
export default {
  props: [''],
  computed: {
    instances() {
      return this.$store.state.instances;
    },
    instance() {
      const instance = this.$store.state.instances.filter((instance) => {
        return instance.attributes.subFolderName == this.$store.state.params.campusHP
      })[0];
      return instance;
    },
    strapiBaseUri() {
      return process.env.strapiBaseUri;
    },
    campusInfo() {
      return this.$store.state.campusInfo;
    },
    detailInfo() {
      return this.$store.state.detailInfo;
    },
    campusHP() {
      return this.$route.params.campusHP
    },
  }

}
